import {
  SET_AUTH_TOKEN,
  LOGIN_PARENT,
  LOGOUT_PARENT,
  SIGNUP_PARENT_STEP_1,
  SIGNUP_PARENT_STEP_2,
  SET_CURRENT_USER,
  CLEAR,
} from '../actions/action_auth';

const INITIAL_STATE = {
  authToken: null,
  currentUserAddress: null,
  currentUserId: null,
  currentUserFirstName: null,
  currentUserLastName: null,
  currentUserEmail: null,
  signupCompleted: null,
  errors: false,
  emailConfirmed: true,
};

export default function (state = INITIAL_STATE, action) {
  Object.freeze(state);

  switch (action.type) {
    case SET_AUTH_TOKEN:
      return { ...state, authToken: action.payload };
    case LOGIN_PARENT:
    case LOGOUT_PARENT:
    case CLEAR:
    case SIGNUP_PARENT_STEP_1:
    case SIGNUP_PARENT_STEP_2:
    case SET_CURRENT_USER:
      if (action.payload.data) {
        if (action.payload.data.errors) return { ...state, errors: true };

        return {
          authToken: action.payload.data.authToken,
          currentUserAddress: action.payload.data.currentUserAddress,
          currentUserEmail: action.payload.data.currentUserEmail,
          currentUserId: action.payload.data.currentUserId,
          currentUserFirstName: action.payload.data.currentUserFirstName,
          currentUserLastName: action.payload.data.currentUserLastName,
          signupCompleted: action.payload.data.signupCompleted,
          errors: false,
          emailConfirmed: false,
        };
      }
      return state;
    default:
      return state;
  }
}
