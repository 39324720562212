import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import cartUtils from '../utils/cart';

const PrivateCheckoutRoute = ({
  auth: { currentUserId, signupCompleted, emailConfirmed },
  component: Component,
  v3Order: { orders, shippingAddressConfirmed },
  ...rest
}) => {
  const cartContainsPostEventOrders =
    cartUtils.cartContainsPostEventOrders(orders);

  const cartContainsShippingRequiredProducts =
    cartUtils.cartContainsShippingRequiredProducts(orders);

  console.log(
    'PrivateCheckoutRoute',
    shippingAddressConfirmed,
    cartContainsPostEventOrders,
    cartContainsShippingRequiredProducts,
    emailConfirmed,
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        !currentUserId || !signupCompleted ? (
          <Redirect
            to={{
              pathname: '/login-redirect',
              state: { reloadRequired: true },
            }}
          />
        ) : shippingAddressConfirmed ? (
          <Component {...props} />
        ) : cartContainsPostEventOrders &&
          cartContainsShippingRequiredProducts ? (
          <Redirect to="/v3/checkout/customer-address-confirmation" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isAddressConfirmed: state.isAddressConfirmed,
  v3Order: state.v3Order,
});

export default connect(mapStateToProps)(PrivateCheckoutRoute);
