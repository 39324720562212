import axios from 'axios';
import { add } from 'lodash';

// export const GET_PARENT_DATA = "GET_PARENT_DATA";
export const ADD_STUDENT = 'ADD_STUDENT';
export const SET_STUDENT_DATA = 'SET_STUDENT_DATA';
export const GET_STUDENTS = 'GET_STUDENTS';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const SET_SMS_PHONE = 'SET_SMS_PHONE';
export const GET_MY_ORDERS = 'GET_ORDERS';
export const SET_PARENT_DATA = 'SET_PARENT_DATA';
export const GET_PARENT_DATA = 'GET_PARENT_DATA';
export const UPDATING_PARENT_DATA = 'UPDATING_PARENT_DATA';
export const UPDATE_PARENT_ADDRESS_SUCCESS = 'UPDATE_PARENT_ADDRESS_SUCCESS';
export const UPDATE_PARENT_ADDRESS_FAILURE = 'UPDATE_PARENT_ADDRESS_FAILURE';
export const UPDATE_PARENT_REQUEST = 'UPDATE_PARENT_REQUEST';
export const UPDATE_PARENT_SUCCESS = 'UPDATE_PARENT_SUCCESS';
export const UPDATE_PARENT_FAILURE = 'UPDATE_PARENT_FAILURE';
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';
export const SEND_EMAIL_CONFIRMATION_REQUEST =
  'SEND_EMAIL_CONFIRMATION_REQUEST';
export const SEND_EMAIL_CONFIRMATION_SUCCESS =
  'SEND_EMAIL_CONFIRMATION_SUCCESS';
export const SEND_EMAIL_CONFIRMATION_FAILURE =
  'SEND_EMAIL_CONFIRMATION_FAILURE';
export const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST';
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE';
export const ADD_STUDENT_SUCCESS = 'ADD_STUDENT_SUCCESS';
export const RESET_SUCCESS_MESSAGE = 'RESET_SUCCESS_MESSAGE';
export const VIEWED_COMPANY_MESSAGE = 'VIEWED_COMPANY_MESSAGE';

const apiUrl = '/api/v2';

export function getParentData() {
  const request = axios.get(`${apiUrl}/parents/get_parent_data`);

  return {
    type: GET_PARENT_DATA,
    payload: request,
  };
}

export function addStudent(data) {
  const request = axios.post(`${apiUrl}/students`, data);

  return {
    type: ADD_STUDENT,
    payload: request,
  };
}

export function getStudents(parentId) {
  const request = axios.get(`${apiUrl}/parents/${parentId}/students`);

  return {
    type: GET_STUDENTS,
    payload: request,
  };
}

export function setStudentData(studentData) {
  console.log('SET STUDENT DATA', studentData);

  return {
    type: SET_STUDENT_DATA,
    payload: studentData,
  };
}

export function updateEmail(id, data) {
  const request = axios.patch(`${apiUrl}/parents/${id}`, data);

  return {
    type: UPDATE_EMAIL,
    payload: request,
  };
}

export function updateAddress(id, data) {
  const request = axios.patch(`${apiUrl}/parents/${id}/address`, data);

  return {
    type: UPDATE_ADDRESS,
    payload: request,
  };
}

export function updateParentAddress(addressData, email, publicToken) {
  return {
    type: UPDATE_PARENT_ADDRESS_SUCCESS,
    payload: addressData,
  };
}

export const updateParent = (requestData) => async (dispatch) => {
  dispatch({ type: UPDATE_PARENT_REQUEST });

  try {
    const response = await axios.patch('/api/v2/parents/update', {
      ...requestData,
    });
    const responseData = response.data;

    dispatch({
      type: UPDATE_PARENT_SUCCESS,
      payload: responseData,
    });

    return response;
  } catch (error) {
    dispatch({ type: UPDATE_PARENT_FAILURE });

    return Promise.reject(error);
  }
};

export const updatePassword = (requestData) => async (dispatch) => {
  dispatch({ type: UPDATE_PASSWORD_REQUEST });

  try {
    const response = await axios.patch('/api/v2/parents/update-password', {
      ...requestData,
    });

    dispatch({ type: UPDATE_PASSWORD_SUCCESS });
    return response;
  } catch (error) {
    dispatch({ type: UPDATE_PASSWORD_FAILURE });
    return Promise.reject(error);
  }
};

export const sendEmailConfirmation = (requestData) => async (dispatch) => {
  dispatch({ type: SEND_EMAIL_CONFIRMATION_REQUEST });

  try {
    const response = await axios.post(
      '/api/v2/parents/send-confirmation-email',
      {
        ...requestData,
      },
    );

    dispatch({
      type: SEND_EMAIL_CONFIRMATION_SUCCESS,
      payload: response.data,
    });
    return response;
  } catch (error) {
    dispatch({ type: SEND_EMAIL_CONFIRMATION_FAILURE });
    return Promise.reject(error);
  }
};

export const confirmEmail = (requestData) => async (dispatch) => {
  dispatch({ type: CONFIRM_EMAIL_REQUEST });
  try {
    const response = await axios.post(
      `/api/v2/parents/${requestData.token}/confirm-email`,
      {
        ...requestData,
      },
    );
    const responseData = response.data;

    dispatch({
      type: CONFIRM_EMAIL_SUCCESS,
      payload: responseData,
    });

    return response;
  } catch (error) {
    dispatch({ type: CONFIRM_EMAIL_FAILURE });

    return Promise.reject(error);
  }
};

export function addStudentSuccess(student) {
  return {
    type: ADD_STUDENT_SUCCESS,
    payload: student,
  };
}

export function setSmsPhone(smsPhone) {
  return {
    type: SET_SMS_PHONE,
    payload: smsPhone,
  };
}

export function getMyOrders(parentId) {
  const request = axios.get(`${apiUrl}/parents/${parentId}/orders`);

  return {
    type: GET_MY_ORDERS,
    payload: request,
  };
}

export function viewedCompanyMessage() {
  return {
    type: VIEWED_COMPANY_MESSAGE,
    payload: true,
  };
}
