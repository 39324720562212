import styled from 'styled-components';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { Button } from '../../../styles/StudentKeyLookupStyles';

export const Wrapper = styled.div`
  padding: 1em 0;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(228px, 1fr));
  gap: 1em;
  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 12px;
  }
`;

export const Image = styled.img`
  width: 100%;
  display: block;
  height: auto;
  background: ${({ background }) =>
    background ? `url("${background}")` : 'none'};
  background-size: contain;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ImageButton = styled(Button)`
  position: absolute;
  bottom: 25px;
  left: 25px;
  right: 25px;
  display: none;
  font-family: 'Inter', sans-serif;
  @media screen and (max-width: 500px) {
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    height: 36px;
    font-size: 16px;
    padding: 0 13px;
    font-weight: 500;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

export const ZoomIcon = styled(MagnifyingGlassIcon)`
  color: white;
  position: absolute;
  width: 45px;
  height: 45px;
  top: 15px;
  right: 15px;
  display: none;
  cursor: pointer;
  border-radius: 3px;
  padding: 2px;
  background-color: #0b76b7;
  @media screen and (max-width: 500px) {
    width: 24px;
    height: 24px;
    top: 4px;
    right: 4px;
    display: block;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  border-width: 6px;
  border-style: solid;
  border-color: transparent;
  &:hover {
    border-color: #0b76b7;
    border-width: 6px;
    ${ZoomIcon}, ${ImageButton} {
      display: block;
    }
  }
  @media screen and (max-width: 500px) {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;
  }
`;

export const CarouselImgWrapper = styled.div`
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CarouselImg = styled(Image)`
  height: calc(100% - 55px);
  width: auto;
  display: block;
  object-fit: cover;
`;

export const ButtonRow = styled.div`
  margin: 1em 0;
  display: flex;
  gap: 10px;
`;

export const SubHeader = styled.h2`
  font-size: 48px;
  font-weight: bold;
  line-height: 58px;
  color: #111928;
  font-family: 'Inter', sans-serif;
`;

export const CategoryWrapper = styled.div`
  margin-bottom: 64px;
`;

export const BlueSubHeader = styled(SubHeader)`
  color: #327ab7;
`;
