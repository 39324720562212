import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 50%; // 1:2 Aspect Ratio

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: contain;
  }
`;

const Offerings = () => {
  const orders = useSelector((state) => state.v3Order.orders);
  const selectedStudent = useSelector(
    (state) => state.additionalData.selectedStudent,
  );
  const shootProducts = useSelector((state) => state.shoot.shootProducts);

  const filteredProducts = shootProducts.filter(
    (category) =>
      category.parent_category_id === null &&
      category.category_type !== 'nth_free',
  );

  const maxIndex = filteredProducts.length - 1;

  const renderCheckoutButton = () => {
    const cartItems = orders[selectedStudent?.id]?.cartItems || [];

    if (cartItems.length > 0) {
      return (
        <Link to="/v3/cart">
          <div className="bnl-product-group__button--right">Check Out Now</div>
        </Link>
      );
    }

    return null;
  };

  const renderNavigationButtons = (index) => {
    if (index === 0) {
      return (
        <div className="bnl-product-group__buttons-container clearfix">
          {renderCheckoutButton()}
          <Link to="/dashboard-redirect">
            <div className="bnl-product-group__button--left" />
          </Link>
        </div>
      );
    }

    return null;
  };

  const offerings = filteredProducts.map((category, index) => {
    let className =
      'bnl-product-group bnl-product-group--small col-xs-12 col-md-6 col-lg-6 cursor-pointer';

    if (index === 0) {
      className =
        'bnl-product-group bnl-product-group--top col-xs-12 col-md-12 col-lg-12 cursor-pointer';
    } else if (
      index === maxIndex &&
      index !== 2 &&
      index % 2 !== 0 &&
      category.display_type !== 'small'
    ) {
      className =
        'bnl-product-group bnl-product-group--bottom col-xs-12 col-md-12 col-lg-12 cursor-pointer';
    }

    return (
      <li key={category.id} className={className}>
        {renderNavigationButtons(index)}
        {shootProducts.length === 1 ? (
          <Link
            to={`/v3/products-regular/${shootProducts[0].products[0].id}`}
            className="categoryLink"
          />
        ) : (
          <Link to={`/v3/categories/${category.id}`} className="categoryLink" />
        )}
        <h3 className="bnl-product-group__header">{category.name}</h3>
        <div className="bnl-product-group__subheader">{category.subheader}</div>
        <div className="bnl-product-group__image-container">
          <ImageContainer>
            <img
              className="bnl-product-group__image--single"
              src={category.image_url}
            />
          </ImageContainer>
        </div>
      </li>
    );
  });

  return (
    <div className="bnl-product-groups-container">
      <ul className="bnl-product-groups row">{offerings}</ul>
    </div>
  );
};

export default Offerings;
