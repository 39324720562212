import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateOrderActiveComponent } from '../../actions/action_order_navigation';
import cartUtils from '../../utils/cart';

// React.js components
import ParentLogoutButton from '../auth/parent_logout_button';

// constants files
import { ORDER_NAVIGATION } from '../../constants/order_navigation';
import {
  GESKUS,
  BNL,
  JELLYJAR,
  GB,
  NORMANDY,
  TIPPING,
  MARCEL,
  CLASSACT,
  WAGNER,
  BIELMAR,
} from '../../constants/companies';
import styled from 'styled-components';

const ConfirmationNeededAlert = styled.div`
  margin-top: 60px;
  margin-bottom: -50px;
`;

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.goToCart = this.goToCart.bind(this);
    this.updateOrderActiveComponentForHomeButton =
      this.updateOrderActiveComponentForHomeButton.bind(this);
    this._shouldNavRender = this._shouldNavRender.bind(this);
  }

  getRedirectLink() {
    const {
      additionalData: { uiVersion, eventUIVersion },
      orderNavigation,
    } = this.props;

    const { location } = this.props;
    const { pathname } = location;

    if (pathname.includes('dashboard')) {
      return '/dashboard';
    }

    if (pathname.includes('lookup-by-student-id')) {
      return '/dashboard';
    }

    if (
      !pathname.includes('/v3/incentive-offers') &&
      pathname.includes('/v3/')
    ) {
      return '/v3/offers';
    }

    if (
      (orderNavigation.step && orderNavigation.step < 3) ||
      ['3', 'v3'].includes(uiVersion) ||
      eventUIVersion === 'ui_v3'
    ) {
      return '/dashboard';
    }

    return '/dashboard';
  }

  renderShootName() {
    if (this.props.orderNavigation.step > 2) {
      return (
        <div className="left__element left__shoot-name">
          {this.props.shoot.name}
        </div>
      );
    }
  }

  renderIcons() {
    const {
      additionalData: { uiVersion, eventUIVersion },
      v3Order: { orders },
    } = this.props;
    const icons = [];
    const companyName = this.getCompanyName();

    // TODO: move this to a utility function
    if (['3', 'v3'].includes(uiVersion) || eventUIVersion === 'ui_v3') {
      icons.push(
        <Link to="/v3/cart" className="link-to-cart">
          <div key="icon-2" className="right__cart-container cursor-pointer">
            <div className={`right__cart right__cart--${companyName}`} />
            <div
              className={`right__cart-count right__cart-count--${companyName}`}
            >
              {cartUtils.getCount(orders)}
            </div>
          </div>
        </Link>,
      );
    } else if (this.props.orderNavigation.step > 2) {
      icons.push(
        <div key="icon-2" className="right__cart-container cursor-pointer">
          <div
            className={`right__cart right__cart--${companyName}`}
            onClick={this.goToCart}
          />
          <div
            className={`right__cart-count right__cart-count--${companyName}`}
          >
            {this.props.cart.count}
          </div>
        </div>,
      );
    }

    return icons;
  }

  _shouldNavRender() {
    if (
      !this.props ||
      !this.props.auth ||
      !this.props.auth.currentUserId ||
      !this.props.auth.signupCompleted ||
      this.props?.location?.pathname?.startsWith('/digital-download')
    ) {
      return false;
    }
    return true;
  }

  // TODO: update to grab data from API
  getCompanyName() {
    return this.props.shoot.shoot.company_auth_token;
  }

  updateOrderActiveComponentForHomeButton() {
    const { orderNavigation, shoot, updateOrderActiveComponent } = this.props;
    if (orderNavigation.step < 3) {
      updateOrderActiveComponent(null);
    } else {
      switch (shoot.shoot.company_auth_token) {
        case GESKUS:
          if (shoot.shoot.ui_version == 'ui_v1') {
            updateOrderActiveComponent(ORDER_NAVIGATION.PRODUCT_GROUPS);
          } else {
            updateOrderActiveComponent(ORDER_NAVIGATION.BNL_PRODUCT_GROUPS);
          }
          return;
        case BNL:
        case JELLYJAR:
        case GB:
        case NORMANDY:
        case TIPPING:
        case MARCEL:
        case CLASSACT:
        case WAGNER:
        case BIELMAR:
          updateOrderActiveComponent(ORDER_NAVIGATION.BNL_PRODUCT_GROUPS);
          return;
        default:
          updateOrderActiveComponent(ORDER_NAVIGATION.BNL_PRODUCT_GROUPS);
      }
    }
  }

  goToCart() {
    let empty = true;
    const {
      cart: { orders },
      history,
      updateOrderActiveComponent,
    } = this.props;

    for (const studentId in this.props.cart.orders) {
      const orderState = this.props.cart.orders[studentId];

      if (orderState.cartItems.length > 0) empty = false;
    }

    if (!empty) {
      switch (this.props.shoot.shoot.company_auth_token) {
        case GESKUS:
          if (this.props.shoot.shoot.ui_version == 'ui_v1') {
            this.props.updateOrderActiveComponent(ORDER_NAVIGATION.CART);
          } else {
            this.props.updateOrderActiveComponent(ORDER_NAVIGATION.BNL_CART);
          }
          return;
        case BNL:
        case JELLYJAR:
        case GB:
        case NORMANDY:
        case TIPPING:
        case MARCEL:
        case CLASSACT:
        case WAGNER:
        case BIELMAR:
          history.push('/order');
          this.props.updateOrderActiveComponent(ORDER_NAVIGATION.BNL_CART);
          return;
        default:
          this.props.updateOrderActiveComponent(ORDER_NAVIGATION.BNL_CART);
      }
    }
  }

  renderStudentName() {
    if (this.props.orderNavigation.step > 2) {
      return (
        <div className="left__element left__student-name">
          {this.props.parent.selectedStudentName}
        </div>
      );
    }
  }

  renderClickAndSafeLink() {
    switch (this.props.companyName) {
      case 'geskusprint':
        return;
      case 'bnlschoolpictures':
      case 'jellyjarphotography':
      case 'grynnandbarrett':
        return <Link to="/click-and-safe">Click And Safe</Link>;
      default:
        return <Link to="/click-and-safe">Click And Safe</Link>;
    }
  }

  renderMyOrdersLink() {
    switch (this.props.companyName) {
      case 'geskusprint':
        return;
      case 'bnlschoolpictures':
      case 'jellyjarphotography':
      case 'grynnandbarrett':
        return <Link to="/my-orders">My Orders</Link>;
      default:
        return <Link to="/my-orders">My Orders</Link>;
    }
  }

  renderBnlPreShootHeader() {
    return null;

    // if (
    //   this.props.bnlPreShoot.preShoot &&
    //   this.props.orderNavigation.step > 3 &&
    //   this.props.orderNavigation.step < 13
    // ) {
    //   const student = this.props.bnlPreShoot.selectedStudent;
    //   const { shootKey } = this.props.bnlPreShoot;

    //   return (
    //     <div className="nav-bar__bnl-pre-shoot-header">
    //       {`Current Order: ${student.firstName} ${student.lastName} (${shootKey})`}
    //     </div>
    //   );
    // }
  }

  render() {
    const navShouldRender = this._shouldNavRender();
    const oneEigthWidth =
      this.props.bnlPreShoot.preShoot && this.props.orderNavigation.step > 3
        ? 'nav-bar__dropdown--preshoot'
        : '';
    const shoppingLinkStyle = {
      paddingLeft: '6px',
      paddingTop: '14px',
      paddingBottom: '14px',
      float: 'left',
    };

    const showShoppingLink = () => {
      if (
        this.props.orderNavigation.step < 3 &&
        this.props.shoot.shoot.company_auth_token === GESKUS
      ) {
        return null;
      }
      const { companyName } = this.props;
      return (
        <Link
          className={`home-link--${companyName}`}
          to={this.getRedirectLink()}
          onClick={this.updateOrderActiveComponentForHomeButton}
          style={shoppingLinkStyle}
        >
          Shopping Home
        </Link>
      );
    };

    // if (!navShouldRender && this.props.companyName === 'bnlschoolpictures') {
    //   return <nav className="bnl-empty-nav" />;
    // }
    if (!navShouldRender) {
      return null;
    }
    // const companyName = this.getCompanyName();
    const { companyName } = this.props;

    return (
      <>
        {this.props.parent?.unconfirmedEmail ? (
          <ConfirmationNeededAlert className="alert alert-warning">
            <p>Please check your email for a confirmation link.</p>
          </ConfirmationNeededAlert>
        ) : (
          <></>
        )}
        <nav className={`navbar navbar--${companyName} navbar-default`}>
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
                aria-expanded="false"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <Link
                to={this.getRedirectLink()}
                onClick={this.updateOrderActiveComponentForHomeButton}
                className="navbar-brand"
              >
                <div
                  className={`left__company-logo left__element ${companyName}-logo--small`}
                />
              </Link>
              {showShoppingLink()}
              <div className="mobileCartContainer">{this.renderIcons()}</div>
            </div>
            {this.renderBnlPreShootHeader()}
            <div
              className="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul className="nav navbar-nav hidden-md hidden-lg hidden-sm">
                <li>
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li>
                  <Link to="/my-account">Account</Link>
                </li>
                <li>{this.renderClickAndSafeLink()}</li>
                <li>{this.renderMyOrdersLink()}</li>
                <li role="separator" className="divider" />
                <li>
                  <a href="#">
                    <ParentLogoutButton companyName={this.props.companyName} />
                  </a>
                </li>
              </ul>
              <ul className={`nav navbar-nav navbar-right ${oneEigthWidth}`}>
                <li>{this.renderIcons()}</li>
                <li className="dropdown">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {`Hi, ${this.props.auth.currentUserFirstName} `}
                    <span className="caret" />
                  </a>
                  <ul className="navbar-dropdown dropdown-menu">
                    <li>
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li>
                      <Link to="/my-account">Account</Link>
                    </li>
                    <li>{this.renderClickAndSafeLink()}</li>
                    <li>{this.renderMyOrdersLink()}</li>
                    <li role="separator" className="divider" />
                    <li>
                      <a href="#">
                        <ParentLogoutButton
                          companyName={this.props.companyName}
                        />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    additionalData: state.additionalData,
    auth: state.auth,
    bnlPreShoot: state.bnlPreShoot,
    cart: state.cart,
    orderNavigation: state.orderNavigation,
    parent: state.parent,
    shoot: state.shoot,
    v3Order: state.v3Order,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateOrderActiveComponent }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
