import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  confirmEmail,
  sendEmailConfirmation,
} from '../../actions/action_parent';

const MainHeader = styled.h4`
  margin: 0;
  font-size: 24px;
`;

const Divider = styled.hr`
  margin: 12px 0 20px 0;
  border-top: 1px solid #ddd;
`;

const Wrapper = styled.div`
  margin-top: 0;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 24px;
  margin-bottom: 32px;
`;

const Alert = styled.div`
  margin-top: 16px;
`;

const ParentConfirmEmail = ({ history }) => {
  const dispatch = useDispatch();
  const [tokenError, setTokenError] = useState('');
  const [resendError, setResendError] = useState('');
  const { email, unconfirmedEmail, hasCart, loading } = useSelector(
    (state) => state.parent,
  );
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  useEffect(() => {
    requestConfirmEmail();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const resendConfirmation = async (formData) => {
    dispatch(sendEmailConfirmation({ unconfirmed_email: formData.email }))
      .then(() => {
        history.push('/my-account');
      })
      .catch((error) => {
        let errorMessages = '';
        if (error.response.data.errors) {
          error.response.data.errors.forEach((message) => {
            errorMessages += message;
          });
        }
        setResendError(errorMessages);
      });
  };

  const requestConfirmEmail = async () => {
    dispatch(confirmEmail({ token }))
      .then(() => {
        if (hasCart) {
          history.push('/v3/checkout');
        } else {
          history.push('/my-account');
        }
      })
      .catch((error) => {
        let errorMessages = '';
        if (error.response.data.errors) {
          error.response.data.errors.forEach((message) => {
            errorMessages += message;
          });
        }
        setTokenError(errorMessages);
      });
  };

  return (
    <div className="bnl-dashboard-container">
      <div className="bnl-dashboard">
        <MainHeader>Confirm your account</MainHeader>
        <Divider />
        <Wrapper>
          {tokenError && (
            <Alert className="alert alert-danger">
              <p>{tokenError}</p>
            </Alert>
          )}
          <form onSubmit={handleSubmit((data) => resendConfirmation(data))}>
            <div className="form-group">
              <label htmlFor="email">Resend a confirmation</label>
              <ul>
                <li>
                  Check that your email is correct, then resend a confirmation
                  email
                </li>
                <li>Click the confirmation link in the most recent email</li>
              </ul>
              <input
                type="text"
                className={`form-control ${errors?.email ? 'is-invalid' : ''}`}
                id="email"
                {...register('email', { required: 'Email is required' })}
                defaultValue={unconfirmedEmail || email}
              />
              {errors.email && (
                <div className="text-danger">{errors?.email.message}</div>
              )}
              {resendError && (
                <div className="text-danger">{`${resendError}`}</div>
              )}
            </div>
            <button type="submit" className="btn btn-primary">
              Resend Confirmation
            </button>
          </form>
        </Wrapper>
      </div>
    </div>
  );
};

export default ParentConfirmEmail;
