import styled from 'styled-components';

const white = '#FFFFFF';
const black = '#000000';

export const StudentCard = styled.div`
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px 24px 0 24px;
  margin-bottom: 36px;
  position: relative;
  @media (max-width: 767px) {
    padding: 16px;
  }
`;

export const SelectPoseBanner = styled.div`
  background-color: #ff0000;
  ${'' /* position: absolute; */}
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  margin: -24px -24px 8px -24px;
  color: white;
  font-weight: bold;
`;

export const ViewGalleryButton = styled.div`
  background-color: #0b76b7;
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  width: fit-content;
  display: inline-block;
  margin: 0 15px;

  &:hover {
    background-color: #357abd; // a darker shade for hover
  }
`;

export const StudentName = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #479de6;
`;

export const EventWrapper = styled.div`
  margin-bottom: 24px;
`;

export const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const GalleryImageContainer = styled.div`
  position: relative;
`;

export const GalleryImage = styled.img`
  max-height: 225px;
  max-width: 180px; /* 8/10 of 225px for larger screens */
  width: auto;
  height: auto;
  object-fit: cover;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // subtle drop shadow
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: 768px) {
    max-width: 135px; /* 8/10 of 225px for smaller screens */
  }
`;

export const SelectedBanner = styled.div`
  background: #0b76b7;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
`;

export const GroupGalleryImage = styled.img`
  max-height: 225px;
  max-width: 281.25px; /* 10/8 of 225px for larger screens */
  width: auto;
  height: auto;
  object-fit: cover;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // subtle drop shadow
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: 768px) {
    max-width: 210.94px; /* 10/8 of 225px for smaller screens */
  }
`;

export const GalleryOrderButton = styled.button`
  background-color: ${white};
  border: 1px solid ${black};
  padding: 8px;
  border-radius: 4px;
  color: ${black}; // Default color
  margin-right: 20px;
  margin-top: 16px;
  &:hover,
  &:active,
  &:focus {
    background-color: ${black};
    color: ${white};
  }
`;

export const EditPoseLink = styled.p`
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
  @media screen and (max-width: 500px) {
  }
`;
